html{
  overflow-y: scroll;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-right: 0px !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.bg-primary{
  background-color: #232F3E!important;
}

.text-bg-info{
  background-color: #232F3E!important;
}

.soko-app-search-wrapper{
  top: 50%;
}

.product-thumbnail{
  width: 60px;
  height: 60px;
  object-fit: contain;
  border: 1px solid #485769;
}

.search-product-img{
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: contain;
  border: 1px solid #485769;
}

.soko-search-results {
    border: 1px solid #232F3E;
    width: 100% !important;
    background: #fff;
    border-radius: 0.375rem;
    position: absolute;
    left: 0;
    top: 100%;
    max-height: 400px !important;
    overflow-y: auto !important;
    overflow-x: hidden!important;
    z-index: 9999!important;
}

.nav-item-font{

font-size: 24px;
}
 

.soko-pointer{

cursor: pointer!important;

}

.form-check-label{

cursor: pointer!important;
}

.sell-btn{
  background-color: #485769!important;
border-color: #485769!important;
}


.sell-btn:hover, .sell-btn:focus, .sell-btn:active {
background-color: #ffbb00!important;
border-color: #ffbb00!important;
}

.btn-primary{
background-color: #232F3E!important;
border-color: #232F3E!important;
}

.btn-primary:hover, .btn-primary:focus, .btn-primary:active {
background-color: #485769!important;
border-color: #485769!important;
}


.text-primary{
color: #232F3E!important;
}

.text-warning{
color: #485769!important;
}


.btn-outline-primary{
color: #232F3E!important;
background-color: transparent!important;
border: 2px solid #232F3E!important;
}

.btn-outline-primary:hover, .btn-outline-primary:focus, .btn-outline-primary:active {
background-color: #232F3E!important;
border: 2px solid #232F3E!important;
color: #fff!important;
}


.btn-warning{
color: #fff;
background-color: #485769!important;
border-color: #485769!important;
}

.btn-warning:hover, .btn-warning:focus, .btn-warning:active {
background-color: #232F3E!important;
border-color: #232F3E!important;
color: #fff;
}

.btn-outline-warning{
color: #485769!important;
background-color: transparent!important;
border: 2px solid #485769!important;
}

.btn-outline-warning:hover, .btn-outline-warning:focus, .btn-outline-warning:active {
background-color: #485769!important;
border: 2px solid #485769!important;
color: #fff!important;
}



 .form-control {
  -webkit-transition-duration: 500ms;
          transition-duration: 500ms;
          border-radius: 0;
  border-style: solid;
    padding: 0.375rem 1rem;
    height:42px;
  font-size: 14px;
  color: #073984;
  background-color: #ffffff;
  border-width: 1px;
}


  .form-control.form-control-clicked {
    background-color: #cfe2ff;
    color: #073984; }
  .form-control:focus {
    -webkit-box-shadow: none !important;
            box-shadow: none !important;
    color: #073984;
    background-color: #ffffff; }
  .form-control.form-control-lg {
    height: 50px;
    padding: 0.625rem 1rem;
    font-size: 16px; }
  .form-control.form-control-sm {
    height: 32px;
    padding: 0.375rem 0.5rem;
    font-size: 12px;
    border-radius: 0.375rem; }

    .table {
  font-size: 14px; }
 
.nav-sell-btn{
  width: 100px;
}

.soko-textzone{

  min-height: 150px!important;
}

.nav-cat-img{
  width: 40px;
  height: 40px;
  object-fit: contain;
}

.soko-ad-img{

    width: 100%;
  height: 211.27px;
  object-fit: contain;
}


.select__control {
     min-height: 50px!important;
}

.desktop-hide{
  display: none;
}
.std-font{

font-size: 12px;
}

.soko-nav-logo{
  width: 34.325px;
height: 38.925px;
}

.soko-header-logo{
width: 50px;
height: 50px;
}

.dropdown-toggle:not(:focus) {
  outline: 0;
}

.product-img-preview{
    height: 200px;
    object-fit: contain;
    width: 100%;
}


.product-upload-card{
    height: 200px;
    position: relative;
  border: 2px dashed #232F3E;
      display: flex;
     justify-content: center;
}

.product-upload-card img {
    height: 100px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}


 .upload-icon{
  height: 100px!important;
 }

.select__menu-list{
  height: 200px!important;
  overflow-y: scroll!important;
  width: 100%;
}
.soko-round{
  border-radius: 30px!important;
}
.soko-rounded{
    border-radius: 0.375rem;
}
.soko-bold{
  font-weight: bold!important;
}
.soko-bolder{
  font-weight: bolder!important;
}
.text-site {
color: #000 !important; 
}

 .nav-link {
color: #000; 

  }

.product-img{
  object-fit: contain;
  height: 200px;
}

.soko-app-search{

  background-color: #485769;

}

.soko-product-card{
  height: 438px;
}



.soko-active{
  color:#485769!important;
}

.soko-footer-icon{

  font-size: 24px!important;
}

.soko-cat-img{

  height: 150px;
  object-fit: cover;
  width: 100%;
}

.card-img-overlay {
    background-color: rgba(0, 0, 0, 0.5);
}

.soko-post-ad-icon{
  font-size: 30px;
}

 

.soko-cat-card:active {
    transform: scale(1.05);
}

.soko-hm-swipe-img{

  height: 384px;
  object-fit: contain;
}

.soko-invisible{

  visibility: hidden;
}

.soko-popup-subcats{
display:none;
position: relative;
}
.soko-popup-cats:hover .soko-popup-subcats{
display:block;
 position: absolute;
        top: -7px;
        right: auto;
}

li{
  list-style-type: none;
}

.soko-home-cats{

  display: none;
}

#footerNav{
  display: none;

}

.soko-post-ad{

    background: #485769!important;

}

.soko-post-add{

    align-items: center;
    background-color: #485769;
    box-shadow: 0 1px 2px rgba(96,125,135,.15);
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 16px;
    text-align: center;
}

  #soko-left-menu
    {
        width:100%;
        float:left;
        background-color: #fff;
        height: 350px;
    }

    .nav-cat-icon{
      margin-top: 12px;
    }

    .soko-cats-menu{
    position: relative;
      padding-left: 0;
    }
  
    .soko-cats-menu li
    {
    font-size: 12px;
    }
  
  .soko-cats-menu > li:hover
    {
        opacity: 1;
        filter: alpha(opacity=100);
    }
  
  .soko-cats-menu li:hover
    {
        background-color:whitesmoke;
    }
  
    .soko-cats-menu li a
    {
    display: block;
        min-width: 150px;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 8px;
    color:#000;
    }
    
  .soko-cats-menu li:hover a
    {
        color:#000;
    
    }

.float-juu-kabisa{
    z-index: 999999!important;

}


.float-juu{
    z-index: 99999!important;

}

.cat-thumbnail{
  height: 100px;
  width: 100px;
  object-fit: cover;
}
 
  
    .soko-cats-menu li ul
    {

    z-index: 9999!important;


      width: 684px;
      max-height: 352px;
      min-height: 352px;
      background-color: white;
      background: #fff;
        position: absolute;
        top: 0;
        left: 100%;
        border: 1px solid #485769;
        border-left: 1px solid #485769;
        display:none;
    }
    
    .soko-cats-menu li:hover > ul
    {
      padding: 10px;
      display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(6, 1fr);

    }
    
    .soko-cats-menu li ul li
    {
        border-top:none;
        border-left:none;
        border-right:none;
    }
  
  .soko-cats-menu li ul li:last-child
    {
    border-bottom: none;
    }

   .mobile-top-ad{
      display: none;
    }

    .desktop-top-ad{
      display: block;
    }

    .carousel-control-prev, .carousel-control-next {
    position: absolute;
    top: 50%;
    transform: TranslateY(-50%);
}

    .carousel-control-next {
    padding: 20px;
    width: 48px;
    height: 48px;
    left: auto;
    right: 20px;
    background-color: rgba(0, 0, 0, .5);
    border-radius: 50%;
}

.carousel-control-prev {
    padding: 20px;
    width: 48px;
    height: 48px;
    right: auto;
    left: 20px;
    background-color: rgba(0, 0, 0, .5);
    border-radius: 50%;
}

    .carousel-control-prev-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}
      
.carousel-control-next-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

.sokomoko-single-product{

height: 300px;
object-fit: contain;
}





/*mobile*/

  @media screen and (max-width: 748px){

    .soko-mobile-font{

  font-size: 12px!important;
}

    .product-img{
  object-fit: contain;
  height: 100px;
}

.page-content-wrapper {

    margin-top: 70px!important;
}

.soko-header-logo{
width: 50px;
height: 50px;
}
    .sell-btn{

  font-size: 14px;
    }

    .soko-nav-text{
  font-size: 12px;

    }

    .soko-home-cats{

  display: none;
}

#footerNav{
  display: block;
  
}

    .desktop-hide{
  display: block;
}

    .mobile-hide{

   display: none!important;
 }

    .nav-tabs .nav-item button{
  font-size: 12px;
}

    .mobile-top-ad{
      display: block;
    }

    .desktop-top-ad{
      display: none;
    }

    #soko_cat_menu{

  display: none;
}

 .mobile-text-hide{

   display: none;
 }

 .soko-footer-icon{

  font-size: 18px!important;
}
 }